import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Navbar = lazy(() => import('./components/Navbar'));
const Footer = lazy(() => import('./components/Footer'));
const Disclaimer = lazy(() => import('./components/Disclaimer'));
const TermsAndConditions = lazy(() => import('./components/TermsAndConditions'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const PressReleases = lazy(() => import('./about/PressRelease'));
const TeachersPage = lazy(() => import('./components/TeachersPage'));
const NotFound = lazy(() => import('./components/NotFound'));
const CourseDetail = lazy(() => import('./courses/CourseDetail'));
const LearnMorePage = lazy(() => import('./components/LearnMore'));
const BlogPost = lazy(() => import('./resources/BlogPost'));
const LiveActivity = lazy(() => import('./components/LiveActivity'));
const ImagePopup = lazy(() => import('./components/ImagePopup'));
const CookieConsent = lazy(() => import('./components/CookieConsent'));
const TestPage = lazy(() => import('./Questionaaire.js/TestPage'));
const SkillTest = lazy(() => import('./Questionaaire.js/SkillTest'));
const BlogPage = lazy(() => import('./resources/BlogPage'));

const Home = lazy(() => import('./Home'));
const About = lazy(() => import('./about/page'));
const Contact = lazy(() => import('./contact/page'));
const Courses = lazy(() => import('./courses/page'));
const Pricing = lazy(() => import('./pricing/page'));
const Resources = lazy(() => import('./resources/page'));
const UploadDoc = lazy(() => import('./documentsupload/upload'));

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Helmet>
          <meta name="author" content="Gourav" />
          <link rel="canonical" href="https://oracy.uk/" />
          <title>Oracy - Develop Your Communication Skills</title>
          <meta
            name="description"
            content="Explore Oracy to develop communication skills with expert-led courses, resources, and skill assessments tailored for all ages."
          />
          <meta property="og:title" content="Oracy - Develop Your Communication Skills" />
          <meta
            property="og:description"
            content="Explore Oracy to develop communication skills with expert-led courses, resources, and skill assessments tailored for all ages."
          />
          <meta property="og:image" content="https://oracy.uk/social-preview.jpg" />
          <meta property="og:url" content="https://oracy.uk/" />
          <meta name="twitter:card" content="summary_large_image" />

          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
          <link
            href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <Suspense fallback={<div>Loading...</div>}>
          <ImagePopup />
          <CookieConsent />
          <Navbar />
          <Routes>
            {/* Routes with Canonical Tags */}
            <Route
              path="/"
              element={
                <>
                  <Helmet>
                    <link rel="canonical" href="https://oracy.uk/" />
                  </Helmet>
                  <Home />
                </>
              }
            />
            <Route
              path="/about"
              element={
                <>
                  <Helmet>
                    <link rel="canonical" href="https://oracy.uk/about" />
                  </Helmet>
                  <About />
                </>
              }
            />
            <Route
              path="/contact"
              element={
                <>
                  <Helmet>
                    <link rel="canonical" href="https://oracy.uk/contact" />
                  </Helmet>
                  <Contact />
                </>
              }
            />
            <Route
              path="/courses"
              element={
                <>
                  <Helmet>
                    <link rel="canonical" href="https://oracy.uk/courses" />
                  </Helmet>
                  <Courses />
                </>
              }
            />
            <Route
              path="/courses/:level"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href={`https://oracy.uk/courses/${window.location.pathname.split('/')[2]}`}
                    />
                  </Helmet>
                  <CourseDetail />
                </>
              }
            />
            <Route
              path="/pricing"
              element={
                <>
                  <Helmet>
                    <link rel="canonical" href="https://oracy.uk/pricing" />
                  </Helmet>
                  <Pricing />
                </>
              }
            />
            <Route
              path="/resources"
              element={
                <>
                  <Resources />
                </>
              }
            />
             <Route
              path="/blogs/latest"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href={`https://oracy.uk/blogs/latest`}
                    />
                  </Helmet>
                  <BlogPage />
                </>
              }
            />
            <Route
              path="/blog/:id"
              element={
                <>
                  <Helmet>
                    <link
                      rel="canonical"
                      href={`https://oracy.uk${window.location.pathname}`}
                    />
                  </Helmet>
                  <BlogPost />
                </>
              }
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/termsandconditions" element={<TermsAndConditions />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/about/press-releases" element={<PressReleases />} />
            <Route path="/teachers" element={<TeachersPage />} />
            <Route path="/learnmore" element={<LearnMorePage />} />
            <Route path="/test-oracy-skills" element={<TestPage />} />
            <Route path="/oracy-skill-test" element={<SkillTest />} />
            <Route path="/upload-documents" element={<UploadDoc />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <LiveActivity />
          <Footer />
        </Suspense>
      </Router>
    </HelmetProvider>
  );
}

export default App;
